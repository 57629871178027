// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-pequena {
    max-width: 50%; 
    height: auto; 
    margin: 0 auto; 
    display: block; 
  }
  .row.justify-content-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .img-pequena {
    max-width: 50%;
    height: auto;
    margin: 0 auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/Styles/Inicio.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;IACZ,cAAc;IACd,cAAc;EAChB;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,YAAY;IACZ,cAAc;EAChB","sourcesContent":[".img-pequena {\n    max-width: 50%; \n    height: auto; \n    margin: 0 auto; \n    display: block; \n  }\n  .row.justify-content-center {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n  }\n  \n  .img-pequena {\n    max-width: 50%;\n    height: auto;\n    margin: 0 auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
