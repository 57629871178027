// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.informes-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .botones-informes {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .btn-informe {
    padding: 15px;
    font-size: 16px;
    color: white;
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-informe:hover {
    background-color: #2980b9;
  }
  `, "",{"version":3,"sources":["webpack://./src/Styles/Informes.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".informes-container {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f4f4f9;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n  \n  h2 {\n    font-size: 24px;\n    margin-bottom: 20px;\n    color: #2c3e50;\n  }\n  \n  .botones-informes {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n  }\n  \n  .btn-informe {\n    padding: 15px;\n    font-size: 16px;\n    color: white;\n    background-color: #3498db;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .btn-informe:hover {\n    background-color: #2980b9;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
