// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-form-body {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

h1 {
  text-align: center;
  color: #259FDE;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

input[type="password"]:focus {
  border-color: #259FDE;
  outline: none;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-submit {
  width: 48%;
  padding: 10px;
  background-color: #3FAE2A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.btn-cancel {
  width: 48%;
  padding: 10px;
  background-color: #D9534F;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}


  
  `, "",{"version":3,"sources":["webpack://./src/Styles/UserForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;AACb;;AAEA;;;;EAIE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB","sourcesContent":[".user-form-body {\n  padding: 20px;\n  background-color: #f5f5f5;\n  border-radius: 8px;\n}\n\nh1 {\n  text-align: center;\n  color: #259FDE;\n  margin-bottom: 20px;\n}\n\n.form-group {\n  margin-bottom: 20px;\n}\n\nlabel {\n  display: block;\n  margin-bottom: 5px;\n  color: #333;\n}\n\ninput[type=\"text\"],\ninput[type=\"password\"],\ninput[type=\"email\"],\nselect {\n  width: 100%;\n  padding: 10px;\n  border-radius: 4px;\n  border: 1px solid #ddd;\n}\n\ninput[type=\"password\"]:focus {\n  border-color: #259FDE;\n  outline: none;\n}\n\n.form-buttons {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 20px;\n}\n\n.btn-submit {\n  width: 48%;\n  padding: 10px;\n  background-color: #3FAE2A;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  font-size: 16px;\n}\n\n.btn-cancel {\n  width: 48%;\n  padding: 10px;\n  background-color: #D9534F;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  font-size: 16px;\n}\n\n\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
