// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-page-body {
    width: 100%;
    height: auto;
    padding: 20px;
    box-sizing: border-box;
    background: #ffffff;
  }
  
  .admin-page-menu {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .menu-item {
    color: #259FDE;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    cursor: pointer;
  }
  
  .welcome-message {
    color: #259FDE;
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    text-align: center;
    margin-top: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Styles/adminPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,gCAAgC;IAChC,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":[".admin-page-body {\n    width: 100%;\n    height: auto;\n    padding: 20px;\n    box-sizing: border-box;\n    background: #ffffff;\n  }\n  \n  .admin-page-menu {\n    display: flex;\n    justify-content: space-around;\n    margin-bottom: 20px;\n  }\n  \n  .menu-item {\n    color: #259FDE;\n    font-size: 20px;\n    font-family: 'Inter', sans-serif;\n    font-weight: 500;\n    cursor: pointer;\n  }\n  \n  .welcome-message {\n    color: #259FDE;\n    font-size: 24px;\n    font-family: 'Inter', sans-serif;\n    font-weight: 400;\n    text-align: center;\n    margin-top: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
