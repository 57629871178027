// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.informes-list-body {
    padding: 20px;
    background-color: #e8f0fe; /* Fondo azul claro */
    border-radius: 8px;
  }
  
  .informes-h1 {
    text-align: center;
    color: #007B83; /* Verde-azulado */
    margin-bottom: 20px;
  }
  
  .informes-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .informes-table th,
  .informes-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  .informes-table th {
    background-color: #007B83; /* Encabezado verde-azulado */
    color: white;
  }
  
  .informes-table td {
    background-color: white;
  }
  
  .btn-edit,
  .btn-delete {
    padding: 8px 12px;
    margin-right: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn-edit {
    background-color: #5CB85C; /* Verde claro */
    color: white;
  }
  
  .btn-edit:hover {
    background-color: #4CAE4C; /* Verde oscuro */
  }
  
  .btn-delete {
    background-color: #D9534F; /* Rojo */
    color: white;
  }
  
  .btn-delete:hover {
    background-color: #C9302C; /* Rojo más oscuro */
  }
  `, "",{"version":3,"sources":["webpack://./src/Styles/InformesList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB,EAAE,qBAAqB;IAChD,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,cAAc,EAAE,kBAAkB;IAClC,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;;IAEE,aAAa;IACb,gBAAgB;IAChB,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB,EAAE,6BAA6B;IACxD,YAAY;EACd;;EAEA;IACE,uBAAuB;EACzB;;EAEA;;IAEE,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB,EAAE,gBAAgB;IAC3C,YAAY;EACd;;EAEA;IACE,yBAAyB,EAAE,iBAAiB;EAC9C;;EAEA;IACE,yBAAyB,EAAE,SAAS;IACpC,YAAY;EACd;;EAEA;IACE,yBAAyB,EAAE,oBAAoB;EACjD","sourcesContent":[".informes-list-body {\n    padding: 20px;\n    background-color: #e8f0fe; /* Fondo azul claro */\n    border-radius: 8px;\n  }\n  \n  .informes-h1 {\n    text-align: center;\n    color: #007B83; /* Verde-azulado */\n    margin-bottom: 20px;\n  }\n  \n  .informes-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-bottom: 20px;\n  }\n  \n  .informes-table th,\n  .informes-table td {\n    padding: 12px;\n    text-align: left;\n    border-bottom: 1px solid #ccc;\n  }\n  \n  .informes-table th {\n    background-color: #007B83; /* Encabezado verde-azulado */\n    color: white;\n  }\n  \n  .informes-table td {\n    background-color: white;\n  }\n  \n  .btn-edit,\n  .btn-delete {\n    padding: 8px 12px;\n    margin-right: 8px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .btn-edit {\n    background-color: #5CB85C; /* Verde claro */\n    color: white;\n  }\n  \n  .btn-edit:hover {\n    background-color: #4CAE4C; /* Verde oscuro */\n  }\n  \n  .btn-delete {\n    background-color: #D9534F; /* Rojo */\n    color: white;\n  }\n  \n  .btn-delete:hover {\n    background-color: #C9302C; /* Rojo más oscuro */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
