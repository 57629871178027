// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-form-body {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  
  h1 {
    text-align: center;
    color: #259FDE;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  input[type="text"],
  input[type="password"],
  input[type="email"],
  select {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  input[type="password"]:focus {
    border-color: #259FDE;
    outline: none;
  }
  
  .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .btn-submit {
    width: 48%;
    padding: 10px;
    background-color: #3FAE2A;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .btn-cancel {
    width: 48%;
    padding: 10px;
    background-color: #D9534F;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  
    
    `, "",{"version":3,"sources":["webpack://./src/Styles/PestView.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,WAAW;EACb;;EAEA;;;;IAIE,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;IACrB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB","sourcesContent":[".user-form-body {\n    padding: 20px;\n    background-color: #f5f5f5;\n    border-radius: 8px;\n  }\n  \n  h1 {\n    text-align: center;\n    color: #259FDE;\n    margin-bottom: 20px;\n  }\n  \n  .form-group {\n    margin-bottom: 20px;\n  }\n  \n  label {\n    display: block;\n    margin-bottom: 5px;\n    color: #333;\n  }\n  \n  input[type=\"text\"],\n  input[type=\"password\"],\n  input[type=\"email\"],\n  select {\n    width: 100%;\n    padding: 10px;\n    border-radius: 4px;\n    border: 1px solid #ddd;\n  }\n  \n  input[type=\"password\"]:focus {\n    border-color: #259FDE;\n    outline: none;\n  }\n  \n  .form-buttons {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n  }\n  \n  .btn-submit {\n    width: 48%;\n    padding: 10px;\n    background-color: #3FAE2A;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n  }\n  \n  .btn-cancel {\n    width: 48%;\n    padding: 10px;\n    background-color: #D9534F;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n  }\n  \n  \n    \n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
