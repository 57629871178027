// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.informes-body {
    width: 100%;
    height: auto;
    background: #444444;
    border-radius: 2px;
    overflow: hidden;
    border: 1px rgba(255, 255, 255, 0.10) solid;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .main-image {
    width: 894px;
    height: 469px;
    display: block;
    margin: 0 auto;
  }
  
  .informes-title {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    color: #259FDE;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 30px;
    word-wrap: break-word;
  }
  `, "",{"version":3,"sources":["webpack://./src/Styles/informesComun.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,2CAA2C;IAC3C,kBAAkB;IAClB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,gCAAgC;IAChC,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;EACvB","sourcesContent":[".informes-body {\n    width: 100%;\n    height: auto;\n    background: #444444;\n    border-radius: 2px;\n    overflow: hidden;\n    border: 1px rgba(255, 255, 255, 0.10) solid;\n    position: relative;\n    padding: 20px;\n    box-sizing: border-box;\n  }\n  \n  .main-image {\n    width: 894px;\n    height: 469px;\n    display: block;\n    margin: 0 auto;\n  }\n  \n  .informes-title {\n    width: 100%;\n    text-align: center;\n    margin-top: 20px;\n    color: #259FDE;\n    font-size: 20px;\n    font-family: 'Inter', sans-serif;\n    font-weight: 500;\n    line-height: 30px;\n    word-wrap: break-word;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
