import React from 'react';
import '../src/Styles/informesComun.css'; // Asegúrate de que la ruta sea correcta

function InformesComun() {
  return (
    <div className="informes-body">
      <img className="main-image" src="https://via.placeholder.com/894x469" alt="Main Report" />
      <div className="informes-title">INFORMES</div>
    </div>
  );
}

export default InformesComun;

